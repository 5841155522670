<template>
  <div>
       <!-- 创建 -->
        <div style="width:700px;margin:0 auto;background:#fff;text-align:left;">

            <el-input placeholder="请输入内容" v-model="saveData.account" size="small" class="baseInput">
                <template slot="prepend">账户编号</template>
            </el-input>
            <el-input placeholder="请输入内容" v-model="saveData.name" size="small" class="baseInput">
                <template slot="prepend">账户名称</template>
            </el-input>
            <div class="add_label" >
                账户类型：<el-select size="small" v-model="saveData.type">
                    <el-option v-for="(item,key) in typeSource" 
                    :label="item.name"
                    :value="item.id"
                    :key="key"></el-option>
                </el-select>
            </div>
            <el-input placeholder="请输入内容" v-model="saveData.beginning_balance" size="small" class="baseInput">
                <template slot="prepend">期初余额</template>
            </el-input>
            <div class="add_label" >
                建帐日期：    <el-date-picker
                                size="small"
                                v-model="saveData.creation_date"
                                format="yyyy-MM-dd"
                                type="date"
                                placeholder="选择日期">
                            </el-date-picker>
            </div>
            <div class="add_label" >
                电商默认账户：<el-switch v-model="saveData.is_commerce"></el-switch>
            </div>
            <div></div>
            <el-input placeholder="收款备注" v-model="saveData.remarks" size="small" style="width:600px;margin-left:20px;">
                <template slot="prepend">收款备注</template>
            </el-input>

            <div slot="footer" class="dialog-footer" style="text-align:center;height:60px;line-height:60px;margin-top:60px;">
                <el-button  @click="exit();">关 闭</el-button>
                <el-button type="primary" @click="save();">保 存</el-button>
            </div>
        </div> 
  </div>
</template>

<script>
import * as dayjs from "dayjs";
import tools from "../../../extends/tools";
import apis from "../../common/js/api2";

export default {
    props:{
        id:{
            type:Number,
            default:0
        }
    },
    data(){
        return {
            fileUrl: tools.baseFileUpload(),
            baseUrl: tools.baseURL(),
            saveDataInit:null,
            typeSource:[],
            saveData:{
                account:'',
                name:'',
                type:'',
                beginning_balance:'',
                creation_date:'',
                remarks:'',
                is_commerce:false
            }
        }
    },
    destroyed(){
        this.saveData = tools.copy(this.saveDataInit);
    },
    methods:{
        copy(code,item){

        },
        beforeUpload(){
            this.uploadLoading = true;
        },
        save(){
            //判断是修改还是新增
            if(this.id > 0){
                this.saveData.id = this.id;
                //保存  
                apis.accountEdit(this.saveData).then((res)=>{
                    if(res.code != 200){
                        this.$message({
                            type:'error',
                            message:res.msg
                        });
                        return;
                    }
                    this.$message({
                        type:'success',
                        message:'保存成功'
                    });
                    //通知列表页更新数据
                    this.fleshList();
                }).catch((err)=>{
                    console.log(err);
                    this.$message('网络不通，请稍候重试-');
                });                
                
                return;
            }

            //新增
            apis.accountAdd(this.saveData).then((res)=>{
                if(res.code != 200){
                    this.$message({
                        type:'error',
                        message:res.msg
                    });
                    return;
                }
                //显示分享码
                this.saveComplete = true;
                this.saveData.is_commerce = !!this.saveData.is_commerce;
                this.$message({
                    type:'success',
                    message:'添加成功'
                });
                //通知列表页更新数据
                this.fleshList();
            }).catch((err)=>{
                console.log(err);
                this.$message('网络不通，请稍候重试-');
            });

        },
        exit(){
            this.$emit('shareExit')
        },
        fleshList(){
            this.$emit('fleshList')
        }
    },
    created(){
        if(this.saveDataInit === null){
            this.saveDataInit = tools.copy(this.saveData);
        }
        //初始化数据源
        apis.accountSource().then(res=>{
            if(tools.msg(res,this)){
                this.typeSource = res.data.type;
            }
        }).catch(err=>{
            toos.err(err,this);
        });

        //判断是修改还是新增
        if(this.id > 0){
            //初始化修改数据
            apis.accountDetail({id:this.id}).then((res)=>{
               res.data.password = ''; 
                this.saveData = res.data;
                this.saveData.id = this.id;
                this.imageUrl = this.baseUrl + res.data.portrait;
            }).catch((err)=>{
                console.log(err);
                this.$message('网络错误，获取数据失败');
            });
        }
    }
}
</script>

<style>
    .baseInput{
      width:280px;margin:15px 20px;
  }
  .add_label{
      display:inline-block;margin:15px 0 15px 20px;width:300px;
  }
</style>