<template>
    <div class="container" :style="{left:selectSide==='left'?'75px':'0',right:selectSide==='right'?'75px':'0'}" >
        <div class="sale" >
            <!-- 顶部导航 -->
            <el-card class="box-card">
                <!-- 按钮 -->
                <el-row style="height:40px;">
                    <el-button type="primary" @click="currentId=0;createShareFlag=true" class="buttons">新增账户</el-button>
                </el-row>
                <!-- 搜索表单 -->
                <el-form :inline="true" class="demo-form-inline" >
                    <el-form-item label="创建时间">
                        <div class="block">
                              <div style="display:inline-block;">
                                <span class="demonstration"></span>
                                <el-date-picker
                                v-model="req.start"
                                type="date"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd 00:00:00"
                                placeholder="开始时间">
                                </el-date-picker>
                            </div>
                            |&nbsp;&nbsp;&nbsp;&nbsp;
                            <div style="display:inline-block;">
                                <span class="demonstration"></span>
                                <el-date-picker
                                v-model="req.end"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd 23:59:59"
                                type="date"
                                placeholder="结束时间">
                                </el-date-picker>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-input
                                placeholder="请输入关键词搜索"
                                prefix-icon="el-icon-search"
                                v-model="req.keywords"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">搜索</el-button>
                        <!-- <el-button @click="onReset">重置</el-button> -->
                    </el-form-item>
                </el-form>
                <div class="all_div">
                    <div  style="top: 40px; transform-origin: 558px 30px;position:relative;">
                        <p style="font-size:14px;margin-bottom:8px;">
                            <span style="font-family:'PingFangSC-Regular', 'PingFang SC';">账户余额</span>
                        </p>
                        <p style="font-size:30px;">
                            <span style="font-family:'PingFangSC-Regular', 'PingFang SC';">¥</span>
                            <span style="font-family:'Arial Normal', 'Arial';">{{allSum}}</span>
                        </p>
                    </div>
                </div>
                <!-- 表格 -->
                <el-table
                        ref="multipleTable"
                        :height="__tableHeight__"
        
        
        
                        :data="tableData"
                        stripe
                        tooltip-effect="dark"
                        style="width: 96%;text-align: center">
                    <el-table-column align="center" type="selection" width="65 "> </el-table-column>
                    <el-table-column label="账户编号"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.account }}</template>
                    </el-table-column>
                   
                    <el-table-column label="账户名称"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.name }}</template>
                    </el-table-column>

                    <el-table-column label="账户类型"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.type }}</template>
                    </el-table-column>

                    <el-table-column label="期初余额"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.beginning_balance }}</template>
                    </el-table-column>

                    <el-table-column label="目前余额"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.balance }}</template>
                    </el-table-column>

                    <el-table-column label="建账日期"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.creation_date }}</template>
                    </el-table-column>

                    <el-table-column label="收款备注"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.remarks }}</template>
                    </el-table-column>

                    <el-table-column fixed="right" label="操作" align="center">
                        <template slot-scope="scope">
                            <!-- <el-button type="text" size="small">
                                数据管理
                            </el-button> -->
                            <el-button type="text" size="small" @click="edit(scope.row.id)" >
                                编辑
                            </el-button>
                            <el-button type="text" size="small"  @click="remove(scope.row.id)">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 页码 -->
                <div class="block">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="req.page"
                            :page-size="req.limit"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total"
                            class="flex-right">
                    </el-pagination>
                </div>
            </el-card>
        </div>

        <!-- 创建分享 -->
        <el-dialog
        :modal-append-to-body="false"
        title="添加账户"
        :visible.sync="createShareFlag"
        v-if="createShareFlag"
        width="800px"
        center>
            <createShare  :id="currentId" @fleshList="getList" @shareExit="shareExit"></createShare>
        </el-dialog>

        <!-- 分享列表 -->
        <el-dialog
        :modal-append-to-body="false"
        title="分享列表"
        :visible.sync="shareListFlag"
        v-if="shareListFlag"
        width="80%"
        center>
            <shareList :id="currentId" ></shareList>
        </el-dialog>
    </div>
</template>

<script>
import createShare from './creatDialog';
import shareListFlag from './listDialog';
import apis from "../../common/js/api2";
import tools from "../../../extends/tools";
export default {
    props:{

    },
    inject: ['selectSide'], 
    data(){
        return {
            //上传图片列表地址
            uploadImages: [

            ],
            createShareFlag:false,
            shareListFlag:false,
            currentId:0,
            tableData:[],
            total:0,
            currentPage:1,
            shareId:0,
            statusList:[],
            allSum:0,
            req:{
                limit:30,
                page:1,
                keywords:''
            }
        }
    },
    components:{
        createShare,shareListFlag
    },
    methods:{
        onSubmit(){
            this.getList();
        },
        handleSizeChange(val) {
            this.req.limit = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.req.page = val;
            this.getList();
        },
        getList(){
            apis.accountList(this.req).then((res) => {
                this.tableData = res.data.data;
                this.tableData.forEach((item,key)=>{
                    for(let i in item){
                        if(i === 'beginning_balance' || i === 'balance'){
                            item[i] = tools.setPrice(item[i]);
                        }
                    }
                });
                this.allSum = res.count;
                this.allSum = tools.setPrice(res.count);
                this.total = res.data.total;
            }).catch((err) => {
                this.$message('网络或程序错误，请稍候重试！', 'error');
            });
        },
        exit(){
            this.$emit('shareExit')
        },
        shareExit(){
            this.createShareFlag = false;
            this.shareListFlag = false;
        },
        remove(id, key) {
            this.$confirm('确定要删除吗?', '删除确认', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                apis.accountDelete({ id: id}).then((info) => {
                    if (info.code == 200) {
                        this.tableData.splice(this.tableData.findIndex(item => item.id === id), 1);
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: info.msg
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        edit(id){
            this.createShareFlag = true;
            this.currentId = id;
        }
    },
    created(){
        console.log(apis);
        this.getList();
    }
}
</script>

<style scoped lang="less">
@import url('../../../assets/less/normal');
.filexls {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
}
.all_div{
    border-width: 0px;
    width: 96%;
    height: 140px;
    background: inherit;
    background-color: rgba(0, 110, 255, 0.0980392156862745);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 110, 255, 1);
    border-radius: 4px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #006EFF;
    text-align: center;
}
</style>